<template>
  <div
    class="product-grid"
    :class="{ 'search-no-product-bg': totItems == 0 && !loading }"
  >
    <v-container>
      <v-row>
        <v-col cols="12">
          <div style="max-height:100%" v-if="totItems > 0">
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="6"
                lg="3"
                xl="4"
                v-for="product in products"
                :key="product.productId"
                class="product-col"
              >
                <ProductCard v-bind:product="product" :position="position" />
              </v-col>
            </v-row>
          </div>
          <div v-else-if="!loading" class="pa-12 text-center">
            <div class="no-products-title">
              Non sono stati trovati prodotti.
            </div>
            <div class="pa-8">
              <img src="/no-products-cart.svg" alt="Nessun prodotto trovato" />
            </div>
            <div class="no-products-legend align-center">
              <i18n path="products.noProductsFound" :tag="false">
                <template v-slot:br><br /></template>
                <template v-slot:link
                  ><router-link
                    :to="{
                      name: 'Page',
                      params: { pageName: 'servizio-clienti' }
                    }"
                  >
                    {{ $t("products.linkServizioClienti") }}
                  </router-link>
                </template>
              </i18n>
            </div>
          </div>
          <v-btn
            block
            v-intersect="onIntersect"
            :loading="loading"
            @click="loadMore"
            v-if="$vuetify.breakpoint.xs && hasMoreItems"
          >
            <template v-if="hasMoreItems">{{
              $t("products.showMore")
            }}</template>
            <template v-else>{{ $t("products.showAll") }}</template>
          </v-btn>

          <v-pagination
            v-if="pager && pager.totPages > 1 && !$vuetify.breakpoint.xs"
            :value="pageFilter"
            :page="pager.selPage"
            :length="pager.totPages ? pager.totPages : 0"
            :totalVisible="6"
            @next.stop.prevent="handlePageFilter"
            @prev="handlePageFilter"
            @input="handlePageFilter"
            prev-icon="$arrowLeft"
            next-icon="$arrowRight"
            navigation-text-color="default"
          ></v-pagination
        ></v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style lang="scss">
.search-no-product-bg.product-grid {
  background-color: var(--v-secondary-lighten2);
}
.no-products-title {
  font-size: 26px;
  line-height: 28px;
  text-align: center;
  color: #18988b;
}
.no-products-legend {
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: $primary;
}
.product-grid {
  margin-top: -68px;

  @media #{map-get($display-breakpoints, 'sm-only')} {
    margin-top: -52px;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    margin-top: 0;
  }

  .mobile-filter-buttons {
    margin-top: 1px;
    .mobile-filters,
    .product-sort-filter {
      border-radius: 0 0 $border-radius-root $border-radius-root;
    }
    .product-sort-filter {
      border-radius: 0 0 $border-radius-root $border-radius-root;
      padding-left: 0 !important;
    }
  }

  .product-sort-filter {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      .v-input__control {
        min-height: 28px !important;
        label {
          color: #000;
          font-size: 11px;
          text-transform: uppercase;
        }
        i {
          color: #000;
        }
        .v-select__selection {
          font-size: 12px;
        }
      }
    }
  }
}
.result-count {
  max-width: 500px;
}
.sort-wrapper {
  .v-input {
    max-width: 250px;
  }
}
</style>
<script>
import ProductCard from "@/components/product/ProductCard.vue";

import ProductService from "~/service/productService";
import analyticsService from "~/service/analyticsService";

import { mapGetters } from "vuex";

export default {
  name: "ProductListGrid",
  props: {
    parentWishlistId: { type: Number, required: false },
    parentCategoryId: { type: Number, required: false },
    categoryId: { type: Number, required: false },
    query: { type: String, required: false },
    barcode: { type: String, required: false },
    mode: { type: String, required: false },
    hidePromoFilter: { type: Boolean, default: false },
    hideNewFilter: { type: Boolean, default: false },
    hidePreferredFilter: { type: Boolean, default: false },
    hideFilterString: { type: String, required: false },
    hideSort: { type: Boolean, default: false },
    category: { type: Object, required: false },
    position: { type: String, required: false }
  },
  components: {
    ProductCard
  },
  data() {
    return {
      products: [],
      facets: [],
      pager: {},
      page: 1,
      busy: false,
      loading: false,
      filteredProductsCount: 0
    };
  },
  computed: {
    queryLabel() {
      return this.barcode ? this.barcode : this.query;
    },
    currentRouteName() {
      return this.$route.name;
    },
    totItems() {
      // return this.pager && this.pager.totItems ? this.pager.totItems : 0;
      return 100;
    },
    ...mapGetters({
      promoFilter: "category/promoFilter",
      newFilter: "category/newFilter",
      preferredFilter: "category/preferredFilter",
      sortFilter: "category/sortFilter",
      pageFilter: "category/pageFilter"
    }),

    facetsFilter: {
      get() {
        return this.$store.state.category.filters.facets;
      },
      set() {
        // this.$store.commit("updatedDrawerLeft", value);
      }
    },

    hasMoreItems() {
      if (this.pager) {
        return this.pager.totPages > this.pager.selPage;
      } else {
        return false;
      }
    }
  },
  methods: {
    // handleSortFilter(sort) {
    //   this.$store.dispatch("category/setFilterSort", sort);
    //   this.fetchProducts();
    // },
    // handlePromoFilter(promo) {
    //   this.$store.dispatch("category/setFilterPromo", promo);
    //   this.fetchProducts();
    // },
    // handleNewFilter(onlyNew) {
    //   this.$store.dispatch("category/setFilterNew", onlyNew);
    //   this.fetchProducts();
    // },
    // handlePreferredFilter(preferred) {
    //   this.$store.dispatch("category/setFilterPreferred", preferred);
    //   this.fetchProducts();
    // },
    handleFacetFilter(filter) {
      this.$store.dispatch("category/setFilterFacets", {
        facets: filter.facets,
        parameterName: filter.parameterName
      });
      this.fetchProducts();
    },
    handleFacetFilterMobile(selectedFacetsXS) {
      this.$store.dispatch("category/setFilterFacetsMobile", {
        facets: selectedFacetsXS
      });
      this.fetchProducts();
    },
    handlePageFilter(page) {
      var _this = this;
      // console.log(page);
      this.$store.dispatch("category/setFilterPage", page);
      //await di this.fetchProduts
      this.fetchProducts();
      //oppure mettere goTo(0) dentro timeout
      setTimeout(function() {
        _this.$vuetify.goTo(0);
      }, 300);
    },

    onIntersect(entries) {
      if (entries[0].isIntersecting) {
        this.loadMore();
      }
    },
    loadMore() {
      if (this.hasMoreItems) {
        this.$store.dispatch("category/setFilterPage", this.pageFilter + 1);
        this.fetchProducts(true);
      }
    },
    async fetchProducts(append) {
      this.loading = true;
      let response = await ProductService.search(
        {
          parent_category_id: this.parentCategoryId,
          parent_wishlist_id: this.parentWishlistId,
          category_id: this.categoryId,
          q: this.query,
          barcode: this.barcode,
          page: this.pageFilter,
          page_size: process.env.VUE_APP_PRODUCT_LIST_PAGE_SIZE,
          sort: this.sortFilter,
          promo: this.promoFilter,
          new_product: this.newFilter,
          preferred: this.preferredFilter
        },
        this.facetsFilter
      );

      // Se ho letto il barcode e mi torna un Prodotto, navigo diretto al Dettaglio
      if (this.barcode && response.products.length > 0) {
        this.$router.push({
          name: "Product",
          params: { slug: response.products[0].slug }
        });
      } else if (append) {
        this.products = [...this.products, ...response.products];
      } else {
        this.products = response.products;
      }
      if (response.products.length > 0) {
        analyticsService.viewProducts(
          response.products,
          this.position,
          (response.page.selPage - 1) * response.page.itemsPerPage
        );
      }
      this.pager = response.page;
      this.facets = response.facets;
      this.$emit("filterUpdated", this.pager.totItems);
      this.filteredProductsCount = response.page.totItems;
      if (this.pager.searchedText) {
        this.$emit("searchedText", this.pager.searchedText);
      }
      this.loading = false;
    },
    selectFilter(e) {
      console.log(e);
    }
  },
  async created() {
    if (this.$vuetify.breakpoint.xsOnly) {
      let page = this.pageFilter;
      for (let i = 1; i <= page; i++) {
        this.$store.dispatch("category/setFilterPage", i);
        await this.fetchProducts(true);
      }
    } else {
      this.fetchProducts();
    }
  }
};
</script>
