<template>
  <div
    class="list-detail-grid list-detail"
    :class="{ 'search-no-product-bg': totalItems == 0 && !loading }"
  >
    <v-container>
      <v-row>
        <v-col cols="12" sm="6">
          <h1 class="pl-3 pl-sm-0 py-3 py-sm-0">
            {{ list.name }}
          </h1>
          <p
            v-if="list.wishlistItems.length > 0"
            v-html="$t('profile.lists.totalCount', [list.wishlistItems.length])"
          ></p>
        </v-col>
        <v-col cols="12" sm="6" class="d-flex justify-end align-end">
          <v-btn
            @click="goToListView"
            class="rounded-pill mb-3"
            large
            outlined
            depressed
            ><v-icon color="primary" class="mr-1" size="26">$listUl</v-icon
            >{{ $t("profile.lists.switchToList") }}</v-btn
          >
        </v-col>
      </v-row>

      <ProductWishlistGrid
        :parent-wishlist-id="list.listId"
        @productsCount="updateTotalItems"
        :hidePreferredFilter="true"
      />
      <SupportCard class="mt-4" v-if="$vuetify.breakpoint.xs" />
    </v-container>
  </div>
</template>
<style>
.list-detail-grid .product-grid {
  margin-top: 0;
}
</style>
<script>
import ListService from "~/service/listService";

import ProductWishlistGrid from "@/components/product/ProductWishlistGrid.vue";
import { mapActions } from "vuex";
import toNumber from "lodash/toNumber";

export default {
  name: "ProductList",
  props: {
    mode: { type: String, required: false },
    category: { type: Object, required: false },
    position: { type: String, required: false }
  },
  components: {
    ProductWishlistGrid
  },
  data() {
    return {
      loading: false,
      list: null,
      totalItems: 0,
      editable: true,
      key: 0
    };
  },
  methods: {
    ...mapActions({
      addProductsFromWishlist: "cart/addProductsFromWishlist"
    }),
    updateTotalItems(count) {
      this.totalItems = count;
    },
    async emptyList() {
      const textConfirm = `Sei sicuro di voler svuotare la lista ${this.list.name}?`;

      let res = await this.$dialog.confirm({
        text: textConfirm
      });
      if (res) {
        const res = await ListService.emptyList(this.list.listId);

        this.list = res;
      }
    },
    async addAllToCart() {
      let res = await global.vm.$dialog.confirm({
        text: global.vm.$t("message.addAllToList")
      });
      if (res) {
        await this.addProductsFromWishlist(this.list.listId);
      }
    },
    goToListView() {
      this.$router.replace({ path: `/profile/lists/${this.list.listId}` });
    },
    async removeFromList(product) {
      let res = await this.$dialog.confirm({
        text: global.vm.$t("message.removeItemFromList", {
          productName: product.name,
          productShortDescr: product.shortDescr
        })
      });
      if (res) {
        let item = this.list.wishlistItems.find(i => {
          return i.product.productId === product.productId;
        });
        if (item) {
          await ListService.removeItemFromList(
            this.list,
            // item.itemId,
            product.productId
          );
          await this.fetchProducts();
        }
      }
    },
    async reload(listId) {
      // this.list = this.$route.params.list;
      this.list = await ListService.getList(toNumber(listId));
      this.editable = this?.list?.sharingData?.grant != "READ";
      this.key++;
    }
  },
  async created() {
    this.reload(this.$route.params.listId);
  },
  watch: {
    async $route(to) {
      this.reload(to.params.listId);
    }
  }
};
</script>
